import React from "react"
import { Styled, css } from "theme-ui"

const Footer = () => (
  <footer
    css={css({
      mt: 4,
      pt: 3,
      mr: 'auto',
      ml: 'auto',
      textAlign: 'center',
      textDecoration: `none`,
      fontSize: 1,
    })}
  >
    © {new Date().getFullYear()}
    {` `}
    <Styled.a href="https://pedro.fyi"
      css={css({
        textDecoration: 'none',
        ":hover": {
          textDecoration: `underline`,
        },
      })}
    >Pedro Costa</Styled.a>
    {` `}&bull;{` `}
    <Styled.a href="https://twitter.com/pedrodc23" target="_blank" rel="noopener noreferrer"
      css={css({
        textDecoration: 'none',
        ":hover": {
          textDecoration: `underline`,
        },
      })}
    >
      Twitter
    </Styled.a>
    {` `}&bull;{` `}
    <Styled.a href="https://www.linkedin.com/in/pedrodcosta/" target="_blank" rel="noopener noreferrer"
      css={css({
        textDecoration: 'none',
        ":hover": {
          textDecoration: `underline`,
        },
      })}
    >
      LinkedIn
    </Styled.a>
  </footer>
)

export default Footer
