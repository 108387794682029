
import React from "react";
import Layout from "../gatsby-theme-blog/components/layout.js"
import SEO from "../gatsby-theme-blog/components/seo"
import Footer from "../gatsby-theme-notes/components/footer"

// 404 page designed for theme. You can copy this in your own site at /src/pages
export default ({...props}) => (
  <Layout {...props} location={props.location} title='pedro.fyi'>
    <SEO title="404"  favicon="techman" />
    <h1>404 - Page not found</h1>
    <p>The page you&apos;re looking for does not exist.</p>
    <Footer/>
  </Layout>
);